import React, { useCallback, useMemo } from 'react';
import {
  BetManager,
  Bus,
  isInactive,
  isSuspended,
  useApplicationState,
  useHighlightedOutcome,
} from '@apollo/core';
import cx from 'classnames';
import _ from 'lodash';
import Theme from 'themeSource/custom';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import { sportService as sportServices } from '../../../core/constants';
import { isMarketOddsClickable, oddsAnimation } from '../../../core/utils';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import OutcomeView from './OutcomeView';

const Outcome = ({
  className = '',
  event,
  event: { sportService },
  market,
  outcome,
  outcome: { odds, marketId, isFake, name },
  marketType,
  suspended,
  disabled,
  indexInRow,
}) => {
  const { selected, placed, editing, loading, failed, succeeded } = useHighlightedOutcome(
    outcome.id,
  );
  const { layout } = useApplicationState();

  const isOutcomeSuspended = suspended || isSuspended(outcome);
  const isOutcomeDisabled = disabled || isInactive(outcome);
  const isArchived = sportService === sportServices.ARCHIVED;
  const isDisabledView = !isArchived && (isOutcomeSuspended || isOutcomeDisabled);

  const eventId = _.get(event, 'id');
  const outcomeId = _.get(outcome, 'id');
  const marketTypeId = _.get(marketType, 'marketTypeId') || _.get(marketType, 'id');
  const displayLayout = _.get(marketType, 'displayLayout', '');
  const betGroups = _.get(event, `marketTypes.${marketTypeId}.betGroups`);

  const selectedPromoOdds = BonusSelector.getPromoOddsByEventIdAndOutcomeId({
    eventId,
    outcomeId,
  }).sort((a, b) => b.getFinalOdds() - a.getFinalOdds())[0];

  const promoOdds = useMemo(
    // promo odds must be greater than odds
    () => (outcome.odds <= selectedPromoOdds?.getFinalOdds() ? selectedPromoOdds?.getFinalOdds() : null),
    [selectedPromoOdds, outcome.odds],
  );

  const onClickHandle = useCallback(
    (e) => {
      // Preventing from adding suspended bets
      if (isOutcomeSuspended || isArchived) {
        return null;
      }

      if (layout.mobileDevice && !selected) {
        const targetElement = e.target.classList.contains('odd-wrapper')
          ? e.target
          : e.target.parentNode.classList.contains('odd-wrapper')
            ? e.target.parentNode
            : e.target.parentNode.parentNode;

        if (promoOdds) {
          Theme.boostedOddsAnimation(targetElement);
        } else {
          oddsAnimation(targetElement, true, odds);
        }
      }

      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.SPORT_PREMATCH,
        event,
        eventId,
        marketTypeId,
        marketId: marketId || _.get(market, 'id'),
        outcomeId,
        betGroups,
        isNew: true,
      });

      // Show Betslip
      if (layout.desktopDevice === true) {
        Bus.send({
          event: Bus.events.layout.setSideBarRight,
          data: true,
        });
      }
    },
    [
      isOutcomeSuspended,
      odds,
      isArchived,
      selected,
      event,
      eventId,
      marketTypeId,
      marketId,
      market,
      outcomeId,
      betGroups,
      layout.mobileDevice,
    ],
  );

  const outcomeClassName = cx(
    className,
    {
      disabled: isDisabledView,
      selected,
      editing,
      placed,
      failed,
      loading,
      succeeded,
    },
    `market--${displayLayout ? displayLayout?.toLowerCase() : 'default'}`,
  );

  if (isFake) {
    return (
      <span
        className={`market bold bet center name isFake ${className} ${
          isDisabledView ? 'disabled' : ''
        }
        ${isMarketOddsClickable(displayLayout) ? 'market--odd-box' : ''}`}
      >
        {name}
      </span>
    );
  }

  if (isOutcomeDisabled && !isOutcomeSuspended && !isArchived) {
    return null;
  }

  return (
    <OutcomeView
      event={event}
      className={outcomeClassName}
      marketType={marketType}
      outcome={outcome}
      onClick={onClickHandle}
      selected={selected}
      indexInRow={indexInRow}
    />
  );
};

export default Outcome;
