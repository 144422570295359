import React, { useCallback, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { at, map } from 'lodash';
import {
  Bus,
  constants,
  getAvailablePages,
  SettingsManager,
  SiteConfigManager,
  useApplicationState,
  useSettings,
} from '@apollo/core';
import {
  accountRoutes,
  availableClientProfileRoutes,
  clientProfileRoutes,
  COMPONENT_TYPES,
} from '@apollo/routing';
import cx from 'classnames';
import { CLIENT_PROFILE_COMPONENT_TYPES } from '@apollo/routing/src/routes/componentTypes';
import HeaderInfoAuthorizedBalance from './HeaderInfoAuthorizedBalance';
import Label from '../../I18n/Label';
import Dropdown from '../../Dropdown/Dropdown';
import { isDateFuture } from '../../../../features/ClientProfile/Bonuses/bonusesUtils';
import { AnimatedDropdown } from '../../../../features/Animations/AnimatedDropdown';
import { BonusSelector } from '../../../../state/Bonus/Bonus';
import HeaderInfoAuthorizedBonuses from './HeaderInfoAuthorizedBonuses';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const onLogOut = () => {
  Bus.emit(Bus.events.auth.logout);
};

const HeaderInfoAuthorized = ({ expanded }) => {
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);
  const { layout, account, wallet } = useApplicationState();
  const toggleRef = useRef(null);

  const bonuses = BonusSelector.selectAllBonuses();

  const hasActiveDepositBonus = BonusSelector.hasActiveDepositBonus();

  const [hold] = at(wallet, ['detailedBalance.HOLD']);

  const { isBalanceHidden } = useSettings();

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs],
  );

  const { payment } = clientProfileRoutes;

  const [isExpanded, setExpanded] = useState(false);

  const toggleHideBalance = useCallback(() => {
    SettingsManager.updateSettings({
      isBalanceHidden: !isBalanceHidden,
    });
  }, [isBalanceHidden]);

  const onClickHandler = useCallback(() => {
    setExpanded(false);
    if (layout.mobileDevice) {
      Bus.send({
        event: Bus.events.layout.setMainMenu,
        data: false,
      });
    }
  }, [layout]);

  if (expanded) {
    return (
      <div className='header__menu__items'>
        <div className='header__menu__title'>
          <Label message='My Account' />
        </div>
        {map(tabs, ({ name, link }) => (
          <NavLink
            key={link}
            to={`${clientProfilePath}/${link}`}
            className='header__menu__item header__menu__item--sub'
            activeClassName='active'
            onClick={onClickHandler}
          >
            <Label message={name} />
          </NavLink>
        ))}

        <div onClick={onLogOut} className='header__menu__item header__menu__item--sub text-danger'>
          <Label message='logout' />
        </div>
        {!isBalanceHidden && <HeaderInfoAuthorizedBalance />}
      </div>
    );
  }

  return (
    <div className='btn-group btn-group--auth'>
      <Dropdown onExpanded={setExpanded} toggleRef={toggleRef} className='header__account'>
        <div ref={toggleRef} className='header__account__btn'>
          {!isBalanceHidden && <HeaderInfoAuthorizedBalance />}
          {!isBalanceHidden && bonuses.length > 0 && (
            <div className='header__account__bet-bonus'>
              <span className='header__account__bet-bonus--counter'>
                {
                  bonuses.filter(
                    (b) => isDateFuture(b.props?.expiry)
                      || b.bonusType === constants.BONUS_TYPE.FREEBET_DEPOSIT,
                  ).length
                }
              </span>
            </div>
          )}
          <div
            className={cx('account_dropdown__info__verification verification-tooltip', {
              account__verified: account?.clientVerificationState?.toLowerCase() === 'verified',
            })}
          >
            <span
              className={cx('verification-symbol', account?.clientVerificationState?.toLowerCase())}
            />
            <span className='verification-tooltiptext'>
              <Label message={account?.clientVerificationState?.toLowerCase()} />
            </span>
          </div>
          <div className='account__current'>
            <div className='user_profile' />
          </div>
        </div>

        <AnimatedDropdown
          isExpanded={isExpanded}
          className={cx('account_dropdown', {
            'account_dropdown--mobile': layout.mobileDevice,
          })}
        >
          <div className='account_dropdown__header'>
            <a
              className='text-danger'
              onClick={() => {
                setExpanded(false);
                onLogOut();
              }}
            >
              <Label message='logout' />
            </a>
            <a className='text-muted' onClick={toggleHideBalance}>
              {isBalanceHidden ? (
                <Label message='show_balance' />
              ) : (
                <Label message='hide_balance' />
              )}
            </a>
          </div>
          <div className='account_dropdown__info'>
            <div className='client_info'>
              <div className='client_info__picture' />
              <div className='client_info__block'>
                <span className='client_info__block__name'>
                  <span className='client_info__block__name--first-name'>{account?.firstName}</span>
                  <span className='client_info__block__name--last-name'>{account?.lastName}</span>
                </span>
                <span className='client_info__block__id'>
                  <Label className='client-info__label text-nowrap' message='User ID' />
                  :&nbsp;
                  {account?.id}
                </span>
              </div>
            </div>
            <NavLink
              to={`${clientProfilePath}/${availableClientProfileRoutes[CLIENT_PROFILE_COMPONENT_TYPES.PROFILE]?.link}/${accountRoutes.verifyDocuments}`}
              className={cx('account_dropdown__info__verification', {
                account__verified: account?.clientVerificationState?.toLowerCase() === 'verified',
              })}
              activeClassName='active'
              onClick={onClickHandler}
            >
              <Label message={account?.clientVerificationState?.toLowerCase()} />
            </NavLink>
          </div>
          <HeaderInfoAuthorizedBonuses onClick={() => setExpanded(false)} />

          <div className='account_dropdown__links'>
            {map(tabs, ({ name, link }) => {
              const depositBonus = hasActiveDepositBonus && name === 'deposit_withdraw';
              return (
                <React.Fragment key={name}>
                  {depositBonus ? (
                    <NavLink
                      key={`${link}_bonus`}
                      to={`${clientProfilePath}/${link}`}
                      activeClassName='active'
                      className={`account-link ${depositBonus ? 'account-link--has-bonus' : ''}`}
                      onClick={() => setExpanded(false)}
                    >
                      <Label message={constants.BONUS_TYPE.FREEBET_DEPOSIT} />
                      {depositBonus && (
                        <i className='icons icon--freebet-black' title='Bonus Bet' />
                      )}
                    </NavLink>
                  ) : null}
                  <NavLink
                    key={link}
                    to={`${clientProfilePath}/${link}`}
                    activeClassName='active'
                    className='account-link'
                    onClick={() => setExpanded(false)}
                  >
                    <Label message={name} />
                    {hold && link === payment ? (
                      <i className='icons icon--withdrawal-pending' />
                    ) : null}
                  </NavLink>
                </React.Fragment>
              );
            })}
          </div>
        </AnimatedDropdown>
      </Dropdown>
    </div>
  );
};

export default HeaderInfoAuthorized;
