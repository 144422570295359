import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { getAvailablePages, SiteConfigManager, useApplicationState } from '@apollo/core';
import { availableClientProfileRoutes, COMPONENT_TYPES } from '@apollo/routing';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import Label from '../../../shared/components/I18n/Label';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { isDateFuture } from './bonusesUtils';
import { BonusSelector } from '../../../state/Bonus/Bonus';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

export const ALL_TAB_NAME = 'bet-bonus-all';

const BonusNavTab = React.memo(({ tab, bonuses, path, activeTab, activeTabRef }) => {
  const t = useTranslate();

  const tabCased = tab?.toLowerCase();

  const active = activeTab === tabCased;

  const isAllTab = tabCased === ALL_TAB_NAME;
  const isBundleTab = tabCased === BONUS_TYPE.BUNDLE?.toLowerCase();

  const counter = isAllTab || isBundleTab
    ? bonuses.length
    : bonuses.filter((b) => b.bonusType?.toLowerCase() === tabCased).length;

  const label = isAllTab ? 'all_bonuses' : tabCased;

  return (
    <NavLink
      key={tab}
      className={cx('bet-bonus--link', { active })}
      to={generatePath(path, { bonusType: tabCased })}
      ref={active ? activeTabRef : null}
    >
      <div className='bet-bonus--icon'>
        <i className={`icons icon--${tabCased}`} title={t(label)} />
      </div>
      <Label message={label} />
      <span className='bet-bonus--counter'>{counter}</span>
    </NavLink>
  );
});

const BonusesNavigation = ({ bonusTabs, activeTab }) => {
  const { authenticated } = useApplicationState();

  const scrollRef = useRef(null);
  const activeTabRef = useRef(null);

  const enableBonusBundles = SiteConfigManager.getConfig(
    'config.config.enable_bonus_bundles',
    false,
  );

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const path = useMemo(() => {
    const tabs = getAvailablePages(availableClientProfileRoutes, clientProfileTabs);
    const bonusLink = tabs?.find((t) => t.type === 'bonuses')?.link || '';
    return `${clientProfilePath}/${bonusLink}/:bonusType?`;
  }, [clientProfilePath, clientProfileTabs]);

  const bonuses = BonusSelector.selectAllBonuses();
  const filteredBonuses = useMemo(
    () => bonuses?.filter((b) => isDateFuture(b.props?.expiry)),
    [bonuses],
  );

  const bundles = BonusSelector.selectAllBundles();
  const filteredBundles = useMemo(
    () => (!enableBonusBundles ? [] : bundles?.filter((b) => isDateFuture(b.expirationTime))),
    [bundles?.length, enableBonusBundles],
  );

  const filteredBonusTabs = useMemo(
    () => bonusTabs.filter(
      (t) => t !== BONUS_TYPE.PROMO_ODDS || filteredBonuses.filter((b) => b.bonusType === t),
    ),
    [bonusTabs, filteredBonuses],
  );

  useEffect(() => {
    if (scrollRef.current && activeTabRef.current) {
      const scrollbars = scrollRef.current;
      const activeTab = activeTabRef.current;

      const tabOffsetLeft = activeTab.offsetLeft;
      const tabWidth = activeTab.clientWidth;

      const scrollLeft = scrollbars.getScrollLeft();
      const containerWidth = scrollbars.getClientWidth();

      if (tabOffsetLeft < scrollLeft || tabOffsetLeft + tabWidth > scrollLeft + containerWidth) {
        scrollbars.scrollLeft(tabOffsetLeft);
      }
    }
  }, [activeTab, bonusTabs]);

  // if (!authenticated || (filteredBonuses.length === 0 && filteredBundles.length === 0)) {
  if (!authenticated) {
    return null;
  }

  return (
    <div className='navigation navigation--secondary bonus-navigation'>
      <Scrollbars ref={scrollRef} className='scroll__container' autoHide>
        <div className='bonus-tabs-container' style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          {enableBonusBundles ? (
            <BonusNavTab
              tab={BONUS_TYPE.BUNDLE}
              activeTab={activeTab}
              bonuses={filteredBundles}
              activeTabRef={activeTabRef}
              path={path}
            />
          ) : null}
          {filteredBonusTabs.map((tab) => (
            <BonusNavTab
              key={tab}
              tab={tab}
              activeTab={activeTab}
              bonuses={filteredBonuses}
              activeTabRef={activeTabRef}
              path={path}
            />
          ))}
        </div>
      </Scrollbars>
    </div>
  );
};

export default BonusesNavigation;
